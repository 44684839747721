/* Utils */
.clearfix:after {
  content: ' ';
  display: block;
  clear: both;
}

.card {
  @include border-radius($borderRadius);
  background: $contentBgColor;
  padding: 18px;
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }

  .card-header {
    @include flex();
    @include flex-align-center();
    @include flex-justify-between();
  }

  .card-subtitle {
    color: $textSecondaryColor;
    font-weight: 600;
    margin: -1rem 0 1rem 0;
  }
}

.p-toast {
  &.p-toast-top-right,
  &.p-toast-top-left,
  &.p-toast-top-center {
    top: 70px;
  }
}

.hover:hover {
  cursor: pointer;
}

.icon-embed {
  .icon-background {
    color: $primaryColor;
  }
  .icon-inside {
    font-size: 0.6em;
    top: 11px;
    left: 9px;
  }
}
