$colors: (
  'blue': #2196f3,
  'green': #4caf50,
  'yellow': #fbc02d,
  'cyan': #00bcd4,
  'pink': #e91e63,
  'indigo': #3f51b5,
  'teal': #009688,
  'orange': #f57c00,
  'bluegray': #607d8b,
  'purple': #9c27b0,
  'primary': $primaryColor,
) !default;

//reused color variables
$shade000: #ffffff; //surface
$shade100: #efefef; //header background
$shade200: #e9ecef; //hover background
$shade300: #dee2e6; //border, divider
$shade400: #ced4da; //input border
$shade500: #adb5bd; //unused
$shade600: #6c757d; //text secondary color
$shade700: #495057; //input text color
$shade800: #343a40; //unused
$shade900: #212529; //text color

//global
$fontFamily: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
  Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$fontSize: 1rem;
$fontWeight: normal;
$textColor: $shade900;
$textSecondaryColor: $shade600;
$borderRadius: 4px;
$transitionDuration: 0.15s;
$formElementTransition: background-color $transitionDuration,
  border-color $transitionDuration, box-shadow $transitionDuration;
$actionIconTransition: box-shadow $transitionDuration;
$listItemTransition: box-shadow $transitionDuration;
$primeIconFontSize: 1rem;
$divider: 1px solid $shade300;
$inlineSpacing: 0.5rem;
$disabledOpacity: 0.65;
$maskBg: rgba(0, 0, 0, 0.4);
$loadingIconFontSize: 2rem;
$errorColor: #dc3545;

//scale
$scaleSM: 0.875;
$scaleLG: 1.25;

//focus
$focusOutlineColor: $primaryLightColor;
$focusOutline: 0 none;
$focusOutlineOffset: 0;
$focusShadow: 0 0 0 0.2rem $focusOutlineColor;

//action icons
$actionIconWidth: 2rem;
$actionIconHeight: 2rem;
$actionIconBg: transparent;
$actionIconBorder: 0 none;
$actionIconColor: $shade600;
$actionIconHoverBg: transparent;
$actionIconHoverBorderColor: transparent;
$actionIconHoverColor: $shade700;
$actionIconBorderRadius: 50%;

//input field (e.g. inputtext, spinner, inputmask)
$inputPadding: 0.5rem 0.75rem;
$inputTextFontSize: 1rem;
$inputBg: $shade000;
$inputTextColor: $shade700;
$inputIconColor: $shade700;
$inputBorder: 1px solid $shade400;
$inputHoverBorderColor: $shade400;
$inputFocusBorderColor: $primaryColor;
$inputErrorBorderColor: $errorColor;
$inputPlaceholderTextColor: $shade600;
$inputFilledBg: $shade100;
$inputFilledHoverBg: $shade100;
$inputFilledFocusBg: $shade100;

//input groups
$inputGroupBg: $shade200;
$inputGroupTextColor: $shade700;
$inputGroupAddOnMinWidth: 2.357rem;

//input lists (e.g. dropdown, autocomplete, multiselect, orderlist)
$inputListBg: $shade000;
$inputListTextColor: $shade900;
$inputListBorder: $inputBorder;
$inputListPadding: 0.5rem 0;
$inputListItemPadding: 0.5rem 1.5rem;
$inputListItemBg: transparent;
$inputListItemTextColor: $shade900;
$inputListItemHoverBg: $shade200;
$inputListItemTextHoverColor: $shade900;
$inputListItemBorder: 0 none;
$inputListItemBorderRadius: 0;
$inputListItemMargin: 0;
$inputListItemFocusShadow: inset 0 0 0 0.15rem $focusOutlineColor;
$inputListHeaderPadding: 0.75rem 1.5rem;
$inputListHeaderMargin: 0;
$inputListHeaderBg: $shade100;
$inputListHeaderTextColor: $shade900;
$inputListHeaderBorder: 1px solid $shade300;

//inputs with overlays (e.g. autocomplete, dropdown, multiselect)
$inputOverlayBg: $inputListBg;
$inputOverlayHeaderBg: $inputListHeaderBg;
$inputOverlayBorder: 1px solid rgba(0, 0, 0, 0.15);
$inputOverlayShadow: none;

//password
$passwordMeterBg: $shade300 !default;
$passwordWeakBg: #d32f2f !default;
$passwordMediumBg: #fbc02d !default;
$passwordStrongBg: #689f38 !default;

//button
$buttonPadding: 0.5rem 0.75rem;
$buttonIconOnlyWidth: 2.357rem;
$buttonIconOnlyPadding: 0.5rem 0;
$buttonBg: $primaryColor;
$buttonTextColor: $primaryTextColor;
$buttonBorder: 1px solid $primaryColor;
$buttonHoverBg: $primaryDarkColor;
$buttonTextHoverColor: $primaryTextColor;
$buttonHoverBorderColor: $primaryDarkColor;
$buttonActiveBg: $primaryDarkerColor;
$buttonTextActiveColor: $primaryTextColor;
$buttonActiveBorderColor: $primaryDarkerColor;
$raisedButtonShadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
  0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
$roundedButtonBorderRadius: 2rem;

$textButtonHoverBgOpacity: 0.04;
$textButtonActiveBgOpacity: 0.16;
$outlinedButtonBorder: 1px solid;
$plainButtonTextColor: $textSecondaryColor;
$plainButtonHoverBgColor: $shade200;
$plainButtonActiveBgColor: $shade300;

$secondaryButtonBg: #6c757d;
$secondaryButtonTextColor: #ffffff;
$secondaryButtonBorder: 1px solid #6c757d;
$secondaryButtonHoverBg: #5a6268;
$secondaryButtonTextHoverColor: #ffffff;
$secondaryButtonHoverBorderColor: #5a6268;
$secondaryButtonActiveBg: #545b62;
$secondaryButtonTextActiveColor: #ffffff;
$secondaryButtonActiveBorderColor: #4e555b;
$secondaryButtonFocusShadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);

$infoButtonBg: #17a2b8;
$infoButtonTextColor: #ffffff;
$infoButtonBorder: 1px solid #17a2b8;
$infoButtonHoverBg: #138496;
$infoButtonTextHoverColor: #ffffff;
$infoButtonHoverBorderColor: #117a8b;
$infoButtonActiveBg: #138496;
$infoButtonTextActiveColor: #ffffff;
$infoButtonActiveBorderColor: #117a8b;
$infoButtonFocusShadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);

$successButtonBg: #28a745;
$successButtonTextColor: #ffffff;
$successButtonBorder: 1px solid #28a745;
$successButtonHoverBg: #218838;
$successButtonTextHoverColor: #ffffff;
$successButtonHoverBorderColor: #1e7e34;
$successButtonActiveBg: #1e7e34;
$successButtonTextActiveColor: #ffffff;
$successButtonActiveBorderColor: #1c7430;
$successButtonFocusShadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);

$warningButtonBg: #ffc107;
$warningButtonTextColor: #212529;
$warningButtonBorder: 1px solid #ffc107;
$warningButtonHoverBg: #e0a800;
$warningButtonTextHoverColor: #212529;
$warningButtonHoverBorderColor: #d39e00;
$warningButtonActiveBg: #d39e00;
$warningButtonTextActiveColor: #212529;
$warningButtonActiveBorderColor: #c69500;
$warningButtonFocusShadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);

$helpButtonBg: #6f42c1;
$helpButtonTextColor: #ffffff;
$helpButtonBorder: 1px solid #6f42c1;
$helpButtonHoverBg: #633bad;
$helpButtonTextHoverColor: #ffffff;
$helpButtonHoverBorderColor: #58349a;
$helpButtonActiveBg: #58349a;
$helpButtonTextActiveColor: #ffffff;
$helpButtonActiveBorderColor: #4d2e87;
$helpButtonFocusShadow: 0 0 0 0.2rem #d3c6ec;

$dangerButtonBg: #dc3545;
$dangerButtonTextColor: #ffffff;
$dangerButtonBorder: 1px solid #dc3545;
$dangerButtonHoverBg: #c82333;
$dangerButtonTextHoverColor: #ffffff;
$dangerButtonHoverBorderColor: #bd2130;
$dangerButtonActiveBg: #bd2130;
$dangerButtonTextActiveColor: #ffffff;
$dangerButtonActiveBorderColor: #b21f2d;
$dangerButtonFocusShadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);

$linkButtonColor: $primaryColor;
$linkButtonHoverColor: $primaryDarkColor;
$linkButtonTextHoverDecoration: underline;
$linkButtonFocusShadow: 0 0 0 0.2rem $focusOutlineColor;

//checkbox
$checkboxWidth: 20px;
$checkboxHeight: 20px;
$checkboxBorder: 2px solid $shade400;
$checkboxIconFontSize: 14px;
$checkboxActiveBorderColor: $primaryColor;
$checkboxActiveBg: $primaryColor;
$checkboxIconActiveColor: $primaryTextColor;
$checkboxActiveHoverBg: $primaryDarkerColor;
$checkboxIconActiveHoverColor: $primaryTextColor;
$checkboxActiveHoverBorderColor: $primaryDarkerColor;

//radiobutton
$radiobuttonWidth: 20px;
$radiobuttonHeight: 20px;
$radiobuttonBorder: 2px solid $shade400;
$radiobuttonIconSize: 12px;
$radiobuttonActiveBorderColor: $primaryColor;
$radiobuttonActiveBg: $primaryColor;
$radiobuttonIconActiveColor: $primaryTextColor;
$radiobuttonActiveHoverBg: $primaryDarkerColor;
$radiobuttonIconActiveHoverColor: $primaryTextColor;
$radiobuttonActiveHoverBorderColor: $primaryDarkerColor;

//colorpicker
$colorPickerPreviewWidth: 2rem;
$colorPickerPreviewHeight: 2rem;
$colorPickerBg: #212529;
$colorPickerBorder: 1px solid #191919 !default;
$colorPickerBorderColor: #212529;
$colorPickerHandleColor: $shade000;

//togglebutton
$toggleButtonBg: #fff;
$toggleButtonBorder: 1px solid $primaryColor;
$toggleButtonTextColor: $primaryColor;
$toggleButtonIconColor: $primaryColor;
$toggleButtonHoverBg: $primaryColor;
$toggleButtonHoverBorderColor: $primaryColor;
$toggleButtonTextHoverColor: #ffffff;
$toggleButtonIconHoverColor: #ffffff;
$toggleButtonActiveBg: $primaryColor;
$toggleButtonActiveBorderColor: $primaryColor;
$toggleButtonTextActiveColor: #ffffff;
$toggleButtonIconActiveColor: #ffffff;
$toggleButtonActiveHoverBg: $primaryColor;
$toggleButtonActiveHoverBorderColor: $primaryColor;
$toggleButtonTextActiveHoverColor: #ffffff;
$toggleButtonIconActiveHoverColor: #ffffff;

//inplace
$inplacePadding: $inputPadding;
$inplaceHoverBg: $shade200;
$inplaceTextHoverColor: $shade900;

//rating
$ratingIconFontSize: 1.143rem;
$ratingCancelIconColor: #dc3545;
$ratingCancelIconHoverColor: #dc3545;
$ratingStarIconOffColor: $shade700;
$ratingStarIconOnColor: $primaryColor;
$ratingStarIconHoverColor: $primaryColor;

//slider
$sliderBg: $shade200;
$sliderBorder: 0 none;
$sliderHorizontalHeight: 0.286rem;
$sliderVerticalWidth: 0.286rem;
$sliderHandleWidth: 1.143rem;
$sliderHandleHeight: 1.143rem;
$sliderHandleBg: $primaryColor;
$sliderHandleBorder: 2px solid $primaryColor;
$sliderHandleBorderRadius: $borderRadius;
$sliderHandleHoverBorderColor: $primaryDarkColor;
$sliderHandleHoverBg: $primaryDarkColor;
$sliderRangeBg: $primaryColor;

//calendar
$calendarTableMargin: 0.5rem 0;
$calendarPadding: 0;
$calendarBg: $shade000;
$calendarInlineBg: $calendarBg;
$calendarTextColor: $shade900;
$calendarBorder: $inputListBorder;
$calendarOverlayBorder: $inputOverlayBorder;

$calendarHeaderPadding: 0.5rem;
$calendarHeaderBg: $shade100;
$calendarInlineHeaderBg: $calendarBg;
$calendarHeaderBorder: 1px solid $shade300;
$calendarHeaderTextColor: $shade900;
$calendarHeaderFontWeight: 600;
$calendarHeaderCellPadding: 0.5rem;
$calendarMonthYearHeaderHoverTextColor: $primaryColor !default;

$calendarCellDatePadding: 0.5rem;
$calendarCellDateWidth: 2.5rem;
$calendarCellDateHeight: 2.5rem;
$calendarCellDateBorderRadius: $borderRadius;
$calendarCellDateBorder: 1px solid transparent;
$calendarCellDateHoverBg: $shade200;
$calendarCellDateTodayBg: $shade400;
$calendarCellDateTodayBorderColor: transparent;
$calendarCellDateTodayTextColor: $shade900;

$calendarButtonBarPadding: 1rem 0;
$calendarTimePickerPadding: 0.5rem;
$calendarTimePickerElementPadding: 0 0.5rem;
$calendarTimePickerTimeFontSize: 1.25rem;

$calendarBreakpoint: 769px;
$calendarCellDatePaddingSM: 0;

//input switch
$inputSwitchWidth: 3rem;
$inputSwitchHeight: 1.75rem;
$inputSwitchBorderRadius: $borderRadius;
$inputSwitchHandleWidth: 1.25rem;
$inputSwitchHandleHeight: 1.25rem;
$inputSwitchHandleBorderRadius: $borderRadius;
$inputSwitchSliderPadding: 0.25rem;
$inputSwitchSliderOffBg: $shade400;
$inputSwitchHandleOffBg: $shade000;
$inputSwitchSliderOffHoverBg: $shade400;
$inputSwitchSliderOnBg: $primaryColor;
$inputSwitchSliderOnHoverBg: $primaryColor;
$inputSwitchHandleOnBg: $shade000;

//panel
$panelHeaderBorderColor: $shade300 !default;
$panelHeaderBorder: 1px solid $shade300;
$panelHeaderBg: $shade100;
$panelHeaderTextColor: $shade900;
$panelHeaderFontWeight: 600;
$panelHeaderPadding: 1rem 1.25rem;
$panelToggleableHeaderPadding: 0.5rem 1.25rem;

$panelHeaderHoverBg: $shade200;
$panelHeaderHoverBorderColor: $shade300;
$panelHeaderTextHoverColor: $shade900;

$panelContentBorderColor: $shade300 !default;
$panelContentBorder: 1px solid $shade300;
$panelContentBg: $shade000;
$panelContentEvenRowBg: scale-color($panelContentBg, $lightness: -1%) !default;
$panelContentTextColor: $shade900;
$panelContentPadding: 1.25rem;

$panelFooterBorder: 1px solid $shade300;
$panelFooterBg: $shade000;
$panelFooterTextColor: $shade900;
$panelFooterPadding: 0.5rem 1.25rem;

//accordion
$accordionSpacing: 0;
$accordionHeaderBorder: $panelHeaderBorder;
$accordionHeaderBg: $panelHeaderBg;
$accordionHeaderTextColor: $panelHeaderTextColor;
$accordionHeaderFontWeight: $panelHeaderFontWeight;
$accordionHeaderPadding: $panelHeaderPadding;

$accordionHeaderHoverBg: $shade200;
$accordionHeaderHoverBorderColor: $shade300;
$accordionHeaderTextHoverColor: $shade900;

$accordionHeaderActiveBg: $panelHeaderBg;
$accordionHeaderActiveBorderColor: $shade300;
$accordionHeaderTextActiveColor: $shade900;

$accordionHeaderActiveHoverBg: $shade200;
$accordionHeaderActiveHoverBorderColor: $shade300;
$accordionHeaderTextActiveHoverColor: $shade900;

$accordionContentBorder: $panelContentBorder;
$accordionContentBg: $panelContentBg;
$accordionContentTextColor: $panelContentTextColor;
$accordionContentPadding: $panelContentPadding;

//tabview
$tabviewNavBorder: 1px solid #dee2e6;
$tabviewNavBorderWidth: 0 0 1px 0;
$tabviewNavBg: transparent;

$tabviewHeaderSpacing: 0;
$tabviewHeaderBorder: solid;
$tabviewHeaderBorderWidth: 1px;
$tabviewHeaderBorderColor: $shade000 $shade000 #dee2e6 $shade000;
$tabviewHeaderBg: $shade000;
$tabviewHeaderTextColor: $shade600;
$tabviewHeaderFontWeight: $panelHeaderFontWeight;
$tabviewHeaderPadding: 0.75rem 1rem;
$tabviewHeaderMargin: 0 0 -1px 0;

$tabviewHeaderHoverBg: $shade000;
$tabviewHeaderHoverBorderColor: #dee2e6;
$tabviewHeaderTextHoverColor: $shade600;

$tabviewHeaderActiveBg: $shade000;
$tabviewHeaderActiveBorderColor: #dee2e6 #dee2e6 $shade000 #dee2e6;
$tabviewHeaderTextActiveColor: $shade700;

$tabviewContentBorder: 0 none;
$tabviewContentBg: $panelContentBg;
$tabviewContentTextColor: $panelContentTextColor;
$tabviewContentPadding: $panelContentPadding;

//upload
$fileUploadProgressBarHeight: 0.25rem;
$fileUploadContentPadding: 2rem 1rem;

//scrollpanel
$scrollPanelTrackBorder: 0 none;
$scrollPanelTrackBg: $shade100;

//card
$cardBodyPadding: 1.5rem;
$cardTitleFontSize: 1.5rem;
$cardTitleFontWeight: 700;
$cardSubTitleFontWeight: 400;
$cardSubTitleColor: $shade600;
$cardContentPadding: 1rem 0;
$cardFooterPadding: 1rem 0 0 0;
$cardShadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
  0 1px 3px 0 rgba(0, 0, 0, 0.12);

//editor
$editorToolbarBg: $panelHeaderBg;
$editorToolbarBorder: $panelHeaderBorder;
$editorToolbarPadding: $panelHeaderPadding;
$editorToolbarIconColor: $textSecondaryColor;
$editorToolbarIconHoverColor: $textColor;
$editorIconActiveColor: $primaryColor;
$editorContentBorder: $panelContentBorder;
$editorContentBg: $panelContentBg;

//paginator
$paginatorBg: $shade000;
$paginatorTextColor: $primaryColor;
$paginatorBorder: solid $shade300;
$paginatorBorderWidth: 0;
$paginatorPadding: 0.75rem;
$paginatorElementWidth: $buttonIconOnlyWidth;
$paginatorElementHeight: $buttonIconOnlyWidth;
$paginatorElementBg: $shade000;
$paginatorElementBorder: 1px solid #dee2e6;
$paginatorElementIconColor: $primaryColor;
$paginatorElementHoverBg: $shade200;
$paginatorElementHoverBorderColor: #dee2e6;
$paginatorElementIconHoverColor: $primaryColor;
$paginatorElementBorderRadius: 0;
$paginatorElementMargin: 0 0 0 -1px;
$paginatorElementPadding: 0;

//table
$tableHeaderBorder: solid #dee2e6;
$tableHeaderBorderWidth: 1px 0 0 0;
$tableHeaderBg: $shade100;
$tableHeaderTextColor: $shade900;
$tableHeaderFontWeight: 600;
$tableHeaderPadding: 1rem 1rem;

$tableHeaderCellPadding: 1rem 1rem;
$tableHeaderCellBg: $shade000;
$tableHeaderCellTextColor: $shade900;
$tableHeaderCellFontWeight: 600;
$tableHeaderCellBorder: 1px solid #dee2e6;
$tableHeaderCellBorderWidth: 1px 0 2px 0;
$tableHeaderCellHoverBg: $shade200;
$tableHeaderCellTextHoverColor: $shade900;
$tableHeaderCellIconColor: $shade600;
$tableHeaderCellIconHoverColor: $shade600;
$tableHeaderCellHighlightBg: $shade000;
$tableHeaderCellHighlightTextColor: $primaryColor;
$tableHeaderCellHighlightHoverBg: $shade200;
$tableHeaderCellHighlightTextHoverColor: $primaryColor;
$tableSortableColumnBadgeSize: 1.143rem;

$tableBodyRowBg: $shade000;
$tableBodyRowTextColor: $shade900;
$tableBodyRowEvenBg: rgba(0, 0, 0, 0.05);
$tableBodyRowHoverBg: $shade200;
$tableBodyRowTextHoverColor: $shade900;
$tableBodyCellBorder: 1px solid #dee2e6;
$tableBodyCellBorderWidth: 1px 0 0 0;
$tableBodyCellPadding: 1rem 1rem;

$tableFooterCellPadding: 1rem 1rem;
$tableFooterCellBg: $shade000;
$tableFooterCellTextColor: $shade900;
$tableFooterCellFontWeight: 600;
$tableFooterCellBorder: 1px solid #dee2e6;
$tableFooterCellBorderWidth: 1px 0 1px 0;
$tableResizerHelperBg: $primaryColor;

$tableFooterBorder: 1px solid #dee2e6;
$tableFooterBorderWidth: 1px 0 1px 0;
$tableFooterBg: $shade100;
$tableFooterTextColor: $shade900;
$tableFooterFontWeight: 600;
$tableFooterPadding: 1rem 1rem;

$tableCellContentAlignment: left;
$tableTopPaginatorBorderWidth: 1px 0 0 0;
$tableBottomPaginatorBorderWidth: 1px 0 0 0;

$tableScaleSM: 0.5;
$tableScaleLG: 1.25;

//dataview
$dataViewContentPadding: 0;
$dataViewContentBorder: 0 none;
$dataViewListItemBorder: 1px solid $shade300;
$dataViewListItemBorderWidth: 1px 0 0 0;

//orderlist, picklist
$orderListBreakpoint: 769px;
$pickListBreakpoint: 769px;

//schedule
$fullCalendarEventBg: $primaryDarkColor;
$fullCalendarEventBorderColor: $primaryDarkColor !default;
$fullCalendarEventBorder: 1px solid $primaryDarkColor;
$fullCalendarEventTextColor: $primaryTextColor;

//tree
$treeContainerPadding: 0.286rem;
$treeNodePadding: 0.143rem;
$treeNodeContentPadding: 0.5rem;
$treeNodeChildrenPadding: 0 0 0 1rem;
$treeNodeIconColor: $shade600;

//timeline
$timelineVerticalEventContentPadding: 0 1rem !default;
$timelineHorizontalEventContentPadding: 1rem 0 !default;
$timelineEventMarkerWidth: 1rem !default;
$timelineEventMarkerHeight: 1rem !default;
$timelineEventMarkerBorderRadius: 50% !default;
$timelineEventMarkerBorder: 0 none !default;
$timelineEventMarkerBackground: $primaryColor !default;
$timelineEventConnectorSize: 2px !default;
$timelineEventColor: $shade300 !default;

//org chart
$organizationChartConnectorColor: $shade300;

//message
$messageMargin: 1rem 0;
$messagePadding: 1rem 1.25rem;
$messageBorderWidth: 1px;
$messageIconFontSize: 1.5rem;
$messageTextFontSize: 1rem;
$messageTextFontWeight: 500;

//inline message
$inlineMessagePadding: $inputPadding;
$inlineMessageMargin: 0;
$inlineMessageIconFontSize: 1rem;
$inlineMessageTextFontSize: 1rem;
$inlineMessageBorderWidth: 0px;

//toast
$toastIconFontSize: 2rem;
$toastMessageTextMargin: 0 0 0 1rem;
$toastMargin: 0 0 1rem 0;
$toastPadding: 1rem;
$toastBorderWidth: 0;
$toastShadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
$toastOpacity: 1;
$toastTitleFontWeight: 700;
$toastDetailMargin: $inlineSpacing 0 0 0;

//severities
$infoMessageBg: #cce5ff;
$infoMessageBorder: solid #b8daff;
$infoMessageTextColor: #004085;
$infoMessageIconColor: #004085;
$successMessageBg: #d4edda;
$successMessageBorder: solid #c3e6cb;
$successMessageTextColor: #155724;
$successMessageIconColor: #155724;
$warningMessageBg: #fff3cd;
$warningMessageBorder: solid #ffeeba;
$warningMessageTextColor: #856404;
$warningMessageIconColor: #856404;
$errorMessageBg: #f8d7da;
$errorMessageBorder: solid #f5c6cb;
$errorMessageTextColor: #721c24;
$errorMessageIconColor: #721c24;

//overlays
$overlayContentBorder: 1px solid rgba(0, 0, 0, 0.2);
$overlayContentBg: $panelContentBg;
$overlayContainerShadow: none;

//dialog
$dialogHeaderBg: $shade000;
$dialogHeaderBorder: 1px solid $shade200;
$dialogHeaderTextColor: $shade900;
$dialogHeaderFontWeight: 600;
$dialogHeaderFontSize: 1.25rem;
$dialogHeaderPadding: 1rem;
$dialogContentPadding: 1rem;
$dialogFooterBorder: 1px solid $shade200;
$dialogFooterPadding: 1rem;

//tooltip
$tooltipBg: $shade900;
$tooltipTextColor: $shade000;
$tooltipPadding: $inputPadding;

//steps
$stepsItemBg: transparent;
$stepsItemBorder: 1px solid $shade300;
$stepsItemTextColor: $shade600;
$stepsItemNumberWidth: 2rem;
$stepsItemNumberHeight: 2rem;
$stepsItemNumberFontSize: 1.143rem;
$stepsItemNumberColor: $shade900;
$stepsItemNumberBorderRadius: $borderRadius;
$stepsItemActiveFontWeight: 600;

//progressbar
$progressBarHeight: 1.5rem;
$progressBarBorder: 0 none;
$progressBarBg: $shade200;
$progressBarValueBg: $primaryColor;
$progressBarValueTextColor: $primaryTextColor !default;

//menu (e.g. menu, menubar, tieredmenu)
$menuWidth: 12.5rem;
$menuBg: $shade000;
$menuBorder: 1px solid $shade300;
$menuTextColor: $shade900;
$menuitemPadding: 0.75rem 1rem;
$menuitemBorderRadius: 0;
$menuitemTextColor: $shade900;
$menuitemIconColor: $shade900;
$menuitemTextHoverColor: $shade900;
$menuitemIconHoverColor: $shade900;
$menuitemHoverBg: $shade200;
$menuitemTextActiveColor: $shade900;
$menuitemIconActiveColor: $shade900;
$menuitemActiveBg: $shade200;
$menuitemSubmenuIconFontSize: 0.875rem;
$submenuHeaderMargin: 0;
$submenuHeaderPadding: 0.75rem 1rem;
$submenuHeaderBg: $shade000;
$submenuHeaderTextColor: $shade900;
$submenuHeaderBorderRadius: 0;
$submenuHeaderFontWeight: 600;
$overlayMenuBg: $menuBg;
$overlayMenuBorder: 1px solid rgba(0, 0, 0, 0.15);
$overlayMenuShadow: none;
$verticalMenuPadding: 0.5rem 0;
$verticalMenuitemMargin: 0;
$menuSeparatorMargin: 0.5rem 0;

$breadcrumbPadding: 1rem;
$breadcrumbBg: $shade100;
$breadcrumbBorder: 0 none;
$breadcrumbItemTextColor: $primaryColor;
$breadcrumbItemIconColor: $primaryColor;
$breadcrumbLastItemTextColor: $shade600;
$breadcrumbLastItemIconColor: $shade600;
$breadcrumbSeparatorColor: $shade600;

$horizontalMenuPadding: 0.5rem 1rem;
$horizontalMenuBg: $shade100;
$horizontalMenuBorder: 0 none;
$horizontalMenuTextColor: rgba(0, 0, 0, 0.9);
$horizontalMenuRootMenuitemPadding: 1rem;
$horizontalMenuRootMenuitemBorderRadius: $borderRadius;
$horizontalMenuRootMenuitemTextColor: rgba(0, 0, 0, 0.5);
$horizontalMenuRootMenuitemIconColor: rgba(0, 0, 0, 0.5);
$horizontalMenuRootMenuitemTextHoverColor: rgba(0, 0, 0, 0.7);
$horizontalMenuRootMenuitemIconHoverColor: rgba(0, 0, 0, 0.7);
$horizontalMenuRootMenuitemHoverBg: transparent;
$horizontalMenuRootMenuitemTextActiveColor: rgba(0, 0, 0, 0.9);
$horizontalMenuRootMenuitemIconActiveColor: rgba(0, 0, 0, 0.9);
$horizontalMenuRootMenuitemActiveBg: transparent;

//badge and tag
$badgeBg: $primaryColor;
$badgeTextColor: $primaryTextColor;
$badgeMinWidth: 1.5rem;
$badgeHeight: 1.5rem;
$badgeFontWeight: 700;
$badgeFontSize: 0.75rem;

$tagPadding: 0.25rem 0.4rem;

//carousel
$carouselIndicatorsPadding: 1rem;
$carouselIndicatorBg: $shade200;
$carouselIndicatorHoverBg: $shade300;
$carouselIndicatorBorderRadius: 0;
$carouselIndicatorWidth: 2rem;
$carouselIndicatorHeight: 0.5rem;

//galleria
$galleriaMaskBg: rgba(0, 0, 0, 0.9);
$galleriaCloseIconMargin: 0.5rem;
$galleriaCloseIconFontSize: 2rem;
$galleriaCloseIconBg: transparent;
$galleriaCloseIconColor: $shade100;
$galleriaCloseIconHoverBg: rgba(255, 255, 255, 0.1);
$galleriaCloseIconHoverColor: $shade100;
$galleriaCloseIconWidth: 4rem;
$galleriaCloseIconHeight: 4rem;
$galleriaCloseIconBorderRadius: $borderRadius;

$galleriaItemNavigatorBg: transparent;
$galleriaItemNavigatorColor: $shade100;
$galleriaItemNavigatorMargin: 0 0.5rem;
$galleriaItemNavigatorFontSize: 2rem;
$galleriaItemNavigatorHoverBg: rgba(255, 255, 255, 0.1);
$galleriaItemNavigatorHoverColor: $shade100;
$galleriaItemNavigatorWidth: 4rem;
$galleriaItemNavigatorHeight: 4rem;
$galleriaItemNavigatorBorderRadius: $borderRadius;

$galleriaCaptionBg: rgba(0, 0, 0, 0.5);
$galleriaCaptionTextColor: $shade100;
$galleriaCaptionPadding: 1rem;

$galleriaIndicatorsPadding: 1rem;
$galleriaIndicatorBg: $shade200;
$galleriaIndicatorHoverBg: $shade300;
$galleriaIndicatorBorderRadius: $borderRadius;
$galleriaIndicatorWidth: 1rem;
$galleriaIndicatorHeight: 1rem;
$galleriaIndicatorsBgOnItem: rgba(0, 0, 0, 0.5);
$galleriaIndicatorBgOnItem: rgba(255, 255, 255, 0.4);
$galleriaIndicatorHoverBgOnItem: rgba(255, 255, 255, 0.6);

$galleriaThumbnailContainerBg: rgba(0, 0, 0, 0.9);
$galleriaThumbnailContainerPadding: 1rem 0.25rem;
$galleriaThumbnailNavigatorBg: transparent;
$galleriaThumbnailNavigatorColor: $shade100;
$galleriaThumbnailNavigatorHoverBg: rgba(255, 255, 255, 0.1);
$galleriaThumbnailNavigatorHoverColor: $shade100;
$galleriaThumbnailNavigatorBorderRadius: $borderRadius;
$galleriaThumbnailNavigatorWidth: 2rem;
$galleriaThumbnailNavigatorHeight: 2rem;

//divider
$dividerHorizontalMargin: 1rem 0;
$dividerHorizontalPadding: 0 1rem;
$dividerVerticalMargin: 0 1rem;
$dividerVerticalPadding: 1rem 0;
$dividerSize: 1px;
$dividerColor: $shade300;

//avatar
$avatarBg: $shade300;
$avatarTextColor: $textColor;

//chip
$chipBg: $shade300;
$chipTextColor: $textColor;
$chipBorderRadius: 16px;

//scrollTop
$scrollTopBg: rgba(0, 0, 0, 0.7);
$scrollTopHoverBg: rgba(0, 0, 0, 0.8);
$scrollTopWidth: 3rem;
$scrollTopHeight: 3rem;
$scrollTopBorderRadius: $borderRadius;
$scrollTopFontSize: 1.5rem;
$scrollTopTextColor: $shade100;

//skeleton
$skeletonBg: $shade200;
$skeletonAnimationBg: rgba(255, 255, 255, 0.4);

//splitter
$splitterGutterBg: $shade100;
$splitterGutterHandleBg: $shade300;

//speeddial
$speedDialButtonWidth: 4rem !default;
$speedDialButtonHeight: 4rem !default;
$speedDialButtonIconFontSize: 1.3rem !default;
$speedDialActionWidth: 3rem !default;
$speedDialActionHeight: 3rem !default;
$speedDialActionBg: $shade700 !default;
$speedDialActionHoverBg: $shade800 !default;
$speedDialActionTextColor: #fff !default;
$speedDialActionTextHoverColor: #fff !default;

//dock
$dockActionWidth: 4rem !default;
$dockActionHeight: 4rem !default;
$dockItemPadding: 0.5rem !default;
$dockCurrentItemMargin: 1.5rem !default;
$dockFirstItemsMargin: 1.3rem !default;
$dockSecondItemsMargin: 0.9rem !default;
$dockBg: rgba(255, 255, 255, 0.1) !default;
$dockBorder: 1px solid rgba(255, 255, 255, 0.2) !default;
$dockPadding: 0.5rem 0.5rem !default;
$dockBorderRadius: 0.5rem !default;

//image
$imageMaskBg: rgba(0, 0, 0, 0.9) !default;
$imagePreviewToolbarPadding: 1rem !default;
$imagePreviewIndicatorColor: #f8f9fa !default;
$imagePreviewIndicatorBg: rgba(0, 0, 0, 0.5) !default;
$imagePreviewActionIconBg: transparent !default;
$imagePreviewActionIconColor: #f8f9fa !default;
$imagePreviewActionIconHoverBg: rgba(255, 255, 255, 0.1) !default;
$imagePreviewActionIconHoverColor: #f8f9fa !default;
$imagePreviewActionIconWidth: 3rem !default;
$imagePreviewActionIconHeight: 3rem !default;
$imagePreviewActionIconFontSize: 1.5rem !default;
$imagePreviewActionIconBorderRadius: 50% !default;

:root {
  --surface-a: #{$shade000};
  --surface-b: #{$shade100};
  --surface-c: #{$shade200};
  --surface-d: #{$shade300};
  --surface-e: #{$shade000};
  --surface-f: #{$shade000};
  --text-color: #{$shade900};
  --text-color-secondary: #{$shade600};
  --primary-color: #{$primaryColor};
  --primary-color-text: #{$primaryTextColor};
  --font-family: #{$fontFamily};
  --surface-0: #ffffff;
  --surface-50: #f9fafb;
  --surface-100: #f8f9fa;
  --surface-200: #e9ecef;
  --surface-300: #dee2e6;
  --surface-400: #ced4da;
  --surface-500: #adb5bd;
  --surface-600: #6c757d;
  --surface-700: #495057;
  --surface-800: #343a40;
  --surface-900: #212529;
  --gray-50: #f9fafb;
  --gray-100: #f8f9fa;
  --gray-200: #e9ecef;
  --gray-300: #dee2e6;
  --gray-400: #ced4da;
  --gray-500: #adb5bd;
  --gray-600: #6c757d;
  --gray-700: #495057;
  --gray-800: #343a40;
  --gray-900: #212529;
  --content-padding: #{$panelContentPadding};
  --inline-spacing: #{$inlineSpacing};
  --border-radius: #{$borderRadius};
  --surface-ground: #efefef;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: #dee2e6;
  --surface-hover: #e9ecef;
  --maskbg: #{$maskBg};
  --focus-ring: #{$focusShadow};
}
