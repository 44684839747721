//general
$fontSize: 14px;
$fontFamily: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol";
$transitionDuration: 0.15s;
$letterSpacing: normal;
$borderRadius: 4px;
$mobileBreakpoint: 991px;

$bodyBgColor: #2e3440;
$textColor: #d8dee9;
$textSecondaryColor: #9199a9;

$topbarBgColor: #2e3440;
$breadcrumbBgColor: #20252e;
$contentBgColor: #20252e;
$contentAltBgColor: #2e3440;
$contentAltBorderColor: #434c5e;
$dividerColor: rgba(90, 101, 124, 0.3);
$menuShadow: 3px 0 5px 0 rgba(33, 35, 39, 0.3);

@import "../overrides/layout_variables";
