.layout-menu-symmetry {
  $menuBgColor: #545a65;
  $menuBgImage: "symmetry.jpg";
  $horizontalMenuBgImage: "symmetry-horizontal.jpg";
  $rootMenuitemTextColor: #eceff4;
  $rootMenuitemIconColor: #eceff4;
  $rootMenuitemHoverBgColor: rgba(255, 255, 255, 0.24);
  $activeRootMenuitemBgColor: #8fbcbb;
  $activeRootMenuitemTextColor: #3b4252;
  $activeRootMenuitemIconColor: #2e3440;
  $submenuBgColor: rgba(255, 255, 255, 0.12);
  $horizontalSubmenuBgColor: #2e3440;
  $submenuItemTextColor: #eceff4;
  $submenuItemIconColor: #eceff4;
  $submenuItemHoverBgColor: rgba(0, 0, 0, 0.42);
  $activeSubmenuItemTextColor: #8fbcbb;
  $activeSubmenuItemIconColor: #8fbcbb;
  $appNameTextColor: #ffffff;
  $pinBgColor: #ffffff;
  $pinIconColor: #2e3440;

  @import "../_menu_theme";
}
