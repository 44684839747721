$gutter: 0.5rem; //for primeflex grid system

@import './styles/theme/romeo/theme-light';
body[data-theme='dark'] {
  @import './styles/theme/romeo/theme-dark';
}

@import './styles/layout/romeo/layout-light';
body[data-theme='dark'] {
  @import './styles/layout/romeo/layout-dark';
}

@import 'node_modules/primeng/resources/primeng.min.css';
@import 'node_modules/primeflex/primeflex.scss';
@import 'node_modules/primeicons/primeicons.css';
// @import "../node_modules/prismjs/themes/prism-coy.css";
// @import "assets/demo/flags/flags.css";
// @import "assets/demo/documentation.scss";

.hover:hover {
  cursor: pointer;
}

@import '@uppy/core/dist/style.css';
@import '@uppy/dashboard/dist/style.css';
@import '@uppy/drag-drop/dist/style.css';
@import '@uppy/progress-bar/dist/style.css';

.p-form-control {
  @include styleclass('flex flex-column-reverse gap-2');
  & > .ng-invalid.ng-dirty + label {
    color: $inputErrorBorderColor;
  }
  & > [required] + label {
    &::after {
      content: ' *';
    }
  }
}
