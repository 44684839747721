$colors: #5e81ac, #81a1c1, #88c0d0, #8fbcbb, #d08770, #ebcb8b, #a3be8c, #b48ead;

.splash-screen {
  width: 100%;
  min-height: 100vh;
  background-color: #f4f7f7;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  height: 40px;
  width: 40px;
  transform: translate(-50%, -50%);
}

[class^="ball-"] {
  position: absolute;
  display: block;
  left: 30px;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  transition: all 0.5s;
  animation: circleRotate 4s both infinite;
  transform-origin: 0 250% 0;
}

@keyframes circleRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1440deg);
  }
}

@for $i from 1 through 8 {
  .ball-#{$i} {
    z-index: -#{$i};
    background-color: nth($colors, $i);
    animation-timing-function: cubic-bezier(0.5, 0.3 * $i, 0.9, 0.9);
  }
}
