.layout-menu-romeo {
  $menuBgColor: #2e3440;
  $rootMenuitemTextColor: #eceff4;
  $rootMenuitemIconColor: #eceff4;
  $rootMenuitemHoverBgColor: #4c566a;
  $activeRootMenuitemBgColor: $primaryColor;
  $activeRootMenuitemTextColor: $primaryTextColor;
  $activeRootMenuitemIconColor: $primaryTextColor;
  $submenuBgColor: rgba(76, 86, 106, 1);
  $horizontalSubmenuBgColor: rgba(76, 86, 106, 1);
  $submenuItemTextColor: #eceff4;
  $submenuItemIconColor: #eceff4;
  $submenuItemHoverBgColor: #2e3440;
  $activeSubmenuItemTextColor: $primaryLightColor;
  $activeSubmenuItemIconColor: $primaryLightColor;
  $appNameTextColor: #ffffff;
  $pinBgColor: #ffffff;
  $pinIconColor: #2e3440;

  @import "../../../sass/layout/menu/menu_theme";
}
